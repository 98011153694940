<template>
    <div style="height:100%;">
        <div class="cont" >
            <p class="title">基本信息</p>
            <p><span style="color:#409EFF">基本信息：</span>{{detailData.templateName}}</p>
            <p><span style="color:#409EFF">备注信息：</span>{{detailData.remarks}}</p>
        </div>
        <div class="cont">
            <p class="rightEdit" @click="openAddDevice()"  style="cursor : pointer;">新增设备</p>
            <p class="title">设备管理</p>
            <div style="padding:20px;overflow:hidden;">
                <div v-for="item in deviceListArr" :key="item.templateDeviceId" style="height:100px;border:1px solid #ddd;background:#eee;padding:15px 10px;width:14%;float:left;margin-right:1%;margin-bottom:20px;">
                    <div style="margin-bottom:10px;">
                        <p style="display:inline-block">{{item.deviceName}}</p>
                        <span v-if="item.productType!=1002" style="float:right;color:#276EF1;cursor : pointer;" @click="delMouldDevice(item.templateDeviceId,item.productType)" >删除</span>
                        <span v-else style="float:right;color:#aaa;cursor : pointer;"  >删除</span>
                        <span style="float:right;color:#276EF1;cursor : pointer;margin-right:10px;" @click="editDevice(item)">编辑</span>
                    </div>
                    <div>
                        <p v-if="item.productType==7005||item.productType==7002||item.productType==7008">品牌：{{item.brandName}}</p>
                        <p v-else style="display:inline-block">位置：{{item.location}}</p>
                        <p v-if="item.productType==121">唤醒词：{{item.position}}</p>
                        <p v-else>别名：{{item.position}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="cont">
            <p class="title">开机语音播报</p>
            <div>
                <input  v-model="detailData.welcomeMsg"  type="text" placeholder="请输入开机语音播报" style="width:500px;margin-top: 15px;margin-right:10px;border: 1px solid #ddd;border-radius: 5px;line-height: 40px;padding: 0 5px;">
                <el-button type="primary"  @click="saveWelcomeMsg()">保存</el-button>
            </div>
        </div>
        <div class="cont">
            <p class="rightEdit" @click="openAddScene('新增')"  style="cursor : pointer;">新增场景</p>
            <p class="title">场景管理</p>
            <div style="padding:20px;overflow:hidden;">
                <div v-for="(item,index) in sceneListData" :key="index" :style="item.adminScene == '1' ? 'border:1px solid #00A521;' : ''" style="overflow:hidden;border-bottom:1px solid #ddd;padding:15px 10px;margin-bottom:20px;">
                    <div style="float:left">
                        <img :src="item.sceneIcon" alt="">
                    </div>
                    <div style="float:left;margin-left:20px;width:83%;">
                        <div style="margin-bottom:10px;">
                            <p style="display: inline-block;font-size:16px;"><span style="color:#409EFF">场景名称：</span>{{item.sceneName}}</p>
                            <!--<span style="float:right;color:#276EF1;margin-left:10px;cursor : pointer;" @click="delScene(item.templateSceneId)" >删除</span>-->
                            <!--<span style="float:right;color:#276EF1;cursor : pointer;" @click="editScene(item.templateSceneId)" >编辑</span>-->
                        </div>
                        <div style="margin-bottom:10px;display: inline-block;"><span style="color:#409EFF">场景类型：</span><span v-if="item.sceneType==1">设备联动</span><span v-else-if="item.sceneType==2">手动触发</span><span v-else-if="item.sceneType==3">语音触发</span></div>
                        <div v-if="item.sceneType==1" style="margin-bottom:10px;display: inline-block;margin-left:20px;"><span style="color:#409EFF">触发条件：</span>
                            <!--酒店门锁-->
                            <div style="display: inline-block;" v-if="item.deviceCondition.productType==11004">
                                <span> 酒店门锁 </span>
                                <!--酒店门锁只有一个动作，不需判断-->
                                <span>打开</span>
                            </div>
                            <!--插卡取电-->
                            <div style="display: inline-block;" v-if="item.deviceCondition.productType==3005">
                                <span> 插卡取电 </span>
                                <!--动作-->
                                <span v-if="item.deviceCondition.value==1">取电</span>
                                <span v-if="item.deviceCondition.value==0">断电</span>
                            </div>
                            <!--三路情景开关-->
                            <div style="display: inline-block;" v-if="item.deviceCondition.productType==3004">
                                <span v-if="item.deviceCondition.identifier=='0.general_scene'"> 一路开关 </span>
                                <span v-if="item.deviceCondition.identifier=='1.general_scene'"> 二路开关 </span>
                                <span v-if="item.deviceCondition.identifier=='2.general_scene'"> 三路开关 </span>
                                <!--动作-->
                                <span>触发</span>
                            </div>
                        </div>
                        <div style="margin-bottom:10px;display: inline-block;margin-left:20px;"><span style="color:#409EFF">设备动作：</span>
                            <div  style="margin-top:10px;display: inline-block;margin-right:10px;" v-for="(deviceItem,index) in item.deviceActions" :key="index">
                                <!--设备-->
                                <span>{{deviceItem.deviceStr}} </span>
                                <!--<span v-if="deviceItem.productType==3001"> 一路开关 </span>-->
                                <!--<span v-else-if="deviceItem.productType==3002"> 二路开关 </span>-->
                                <!--<span v-else-if="deviceItem.productType==3003"> 三路开关 </span>-->
                                <!--<span v-else-if="deviceItem.productType==4001"> 方块插座 </span>-->
                                <!--<span v-else-if="deviceItem.productType==6002"> 窗帘电机(WiFi版) </span>-->
                                <!--<span v-else-if="deviceItem.productType==6003"> 窗帘电机(ZIgBee版) </span>-->
                                <!--<span v-else-if="deviceItem.productType==2001"> 调色灯 </span>-->
                                <!--&lt;!&ndash;动作&ndash;&gt;-->
                                <!--  一路开关  二路开关   三路开关   方块插座-->
                                <div style="display: inline-block;" v-if="deviceItem.productType==3001||deviceItem.productType==3002||deviceItem.productType==3003">
                                    <span v-if="deviceItem.identifier=='0.general_onoff'"> 一路 </span>
                                    <span v-if="deviceItem.identifier=='1.general_onoff'"> 二路 </span>
                                    <span v-if="deviceItem.identifier=='2.general_onoff'"> 三路 </span>
                                    <!--动作-->
                                    <span v-if="deviceItem.value==1">打开</span>
                                    <span v-if="deviceItem.value==0">关闭</span>，
                                </div>
                                <!--方块插座-->
                                <div style="display: inline-block;" v-if="deviceItem.productType==4001">
                                    <span> 开关 </span>
                                    <!--动作-->
                                    <span v-if="deviceItem.value==1">打开</span>
                                    <span v-if="deviceItem.value==0">关闭</span>，
                                </div>
                                <!--  窗帘电机-->
                                <div style="display: inline-block;" v-else-if="deviceItem.productType==6002||deviceItem.productType==6003">
                                    <span> 进度 </span>
                                    <!--动作-->

                                    <span v-if="deviceItem.value==='0'">关，</span>
                                    <span v-if="deviceItem.value==='1'">开，</span>
                                    <span v-if="deviceItem.value!='0'&&deviceItem.value!='1'">{{deviceItem.value*100}}%，</span>
                                </div>
                                <!--  调色灯 -->
                                <div style="display: inline-block;" v-else-if="deviceItem.productType==2001">
                                    <div v-if="deviceItem.identifier=='0.general_onoff'"> 开关
                                        <span v-if="deviceItem.value==1">打开</span>
                                        <span v-if="deviceItem.value==0">关闭</span>，
                                    </div>
                                    <div v-if="deviceItem.identifier=='0.general_level'"> 亮度
                                        <span>{{deviceItem.value*100}}%</span>，
                                    </div>
                                    <div v-if="deviceItem.identifier=='0.colorctrl_saturation'"> 饱和度
                                        <span>{{deviceItem.value*100}}%</span>，
                                    </div>
                                    <div v-if="deviceItem.identifier=='0.colorctrl_hue'"> 颜色
                                        <span v-if="deviceItem.value==230">蓝色</span>
                                        <span v-else-if="deviceItem.value==355">红色</span>
                                        <span v-else-if="deviceItem.value==130">绿色</span>
                                        <span v-else-if="deviceItem.value==51">黄色</span>
                                        <span v-else-if="deviceItem.value==35">橙色</span>
                                        <span v-else-if="deviceItem.value==293">紫色</span>，
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="float:right;color:#276EF1;cursor : pointer;">
                        <span style="" @click="editScene(item.templateSceneId)" >编辑</span>
                        <span style="margin-left:10px;" @click="delScene(item.templateSceneId)" >删除</span>
                    </div>
                </div>
            </div>
        </div>


        <!--新增设备对话框-->
        <el-dialog title="添加设备"  :visible.sync="deviceVisible" width="70%" :close-on-click-modal="false">
            <div style="overflow:hidden;height:95%;">
                <div style="float:left;width:25%;border-right:1px solid #ddd;padding:10px;height:100%;overflow:scroll">
                    <p style="font-size:18px;margin-bottom:10px;">网关设备</p>
                    <div style="margin:5px;margin-left:15px;" v-for="item in gatewayList" :key="item.deviceName">
                        <p style="display:inline-block;font-size:16px;">{{item.deviceName}}</p>
                        <i class="iconfont icon-tianjia-m" style="float:right;font-size:20px;cursor:pointer;color:#ddd;"></i>
                    </div>
                    <p style="font-size:18px;margin-bottom:10px;">ZigBee子设备</p>
                    <div style="margin:5px;margin-left:15px;" v-for="item in selDeviceList" :key="item.deviceName">
                        <p style="display:inline-block;font-size:16px;">{{item.deviceName}}</p>
                        <i class="iconfont icon-tianjia-m" style="float:right;font-size:20px;cursor:pointer;" @click="addDevice(item)"></i>
                    </div>
                    <div  v-bind:style="{ color: isAdd ? '' : '#aaa' }">
                        <p style="font-size:18px;margin-bottom:10px;">红外可控设备</p>
                        <div style="margin:5px;margin-left:15px;" v-for="item in infraredDeviceList" :key="item.deviceName">
                            <p style="display:inline-block;font-size:16px;">{{item.deviceName}}</p>
                            <i class="iconfont icon-tianjia-m" style="float:right;font-size:20px;cursor:pointer;" disabled="true" @click="addDevice(item)"></i>
                        </div>
                    </div>

                </div>
                <div style="float:left;width:68%;padding:10px;height:99%;overflow:scroll">
                    <!--<p style="font-size:18px;margin-bottom:10px;">已选设备</p>-->
                    <div v-for="(item,index) in deviceAddList" :key="index" style="border:1px solid #ddd;border-radius:5px;padding:5px;margin-right:10px;margin-bottom:10px;">
                        <i class="iconfont icon-shanchu" style="float:right;font-size:20px;" @click="delDevice(index)"></i>
                        <p style="font-size:20px;">{{item.deviceName}}</p>
                        <el-select v-if="item.productType==7005||item.productType==7002||item.productType==7008" v-model="item.brandCode" @click.native="getCodeByType(item.productType)"  placeholder="请选择品牌" style="margin-top: 15px;display: block;width:180px">
                            <el-option v-for="infraredItem in infraredCodeList" :key="infraredItem.brandCode" :label="infraredItem.brandName" :value="infraredItem.brandCode"></el-option>
                        </el-select>

                        <input v-else v-model="item.location" :aria-required="true" type="text" placeholder="请输入位置" onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;" style="display:block;margin-top: 15px;margin-right:10px;border: 1px solid #ddd;border-radius: 5px;line-height: 40px;padding: 0 5px;">
                        <div v-if="item.productType!=121">
                            <input onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;" v-for="(n,index) in item.position" :key="index" v-model="n.value" :aria-required="true" type="text" placeholder="请输入别名" style="margin-top: 15px;margin-right:10px;border: 1px solid #ddd;border-radius: 5px;line-height: 40px;padding: 0 5px;">
                        </div>
                        <div v-else>
                            <input onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;" v-for="(n,index) in item.position" :key="index" v-model="n.value" :aria-required="true" type="text" placeholder="请输入唤醒词" style="margin-top: 15px;margin-right:10px;border: 1px solid #ddd;border-radius: 5px;line-height: 40px;padding: 0 5px;">
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="deviceVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitDevice()">确 定</el-button>
            </span>
        </el-dialog>

        <!--编辑设备对话框-->
        <el-dialog title="编辑设备"  :visible.sync="editDeviceVisible" width="32%" class="smallDialog" :close-on-click-modal="false">
            <el-form  :model="deviceForm" :rules="ruleDevice" ref="deviceForm" status-icon class="demo-ruleForm">
                <el-form-item label="" prop="deviceName">
                    <div>设备名称</div>
                    <el-input type="text" v-model="deviceForm.deviceName" autocomplete="off" style="width:300px;" disabled></el-input>
                </el-form-item>
                <el-form-item label="" v-if="deviceForm.productType==7005||deviceForm.productType==7002||deviceForm.productType==7008" prop="brandCode">
                    <div>品牌</div>
                    <el-select v-model="deviceForm.brandCode" @click.native="getCodeByType(deviceForm.productType)"  placeholder="请选择品牌" style="margin-top: 15px;display: block;width:180px">
                        <el-option v-for="infraredItem in infraredCodeList" :key="infraredItem.brandCode" :label="infraredItem.brandName" :value="infraredItem.brandCode"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" v-else prop="location">
                    <div>位置</div>
                    <el-input :aria-required="true" type="text" v-model="deviceForm.location" onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;" placeholder="请输入位置" style="display:block;margin-top: 15px;margin-right:10px;width:180px;"></el-input>
                </el-form-item>
                <el-form-item label="" prop="position">
                    <div>别名</div>
                    <el-input  v-for="(n,index) in deviceForm.position" :key="index" v-model="n.value" onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;" :aria-required="true" type="text" placeholder="请输入别名" style="width:180px;margin-right:10px;"></el-input>
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button @click="editDeviceVisible = false">取 消</el-button>
                    <el-button type="primary" @click="updateDevice()">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--新增场景对话框-->
        <el-dialog title="添加场景"  :visible.sync="sceneVisible" width="50%" :close-on-click-modal="false">
            <el-form :model="sceneForm" :rules="ruleScene" status-icon ref="sceneForm" class="demo-ruleForm" style="height: 100%;overflow: scroll;">
                <el-form-item label="" prop="sceneName">
                    <div>场景名称</div>
                    <el-input type="text" v-model="sceneForm.sceneName" autocomplete="off" :disabled="sceneForm.adminScene == '1'" style="width:400px;margin-left:5px;"></el-input>
                </el-form-item>
                <el-form-item label="场景类型">
                    <el-radio-group v-model="sceneForm.sceneType" :disabled="sceneForm.adminScene == '1'">>
                        <el-radio v-model="sceneForm.sceneType" :label="1">设备联动</el-radio>
                        <el-radio v-model="sceneForm.sceneType" :label="3" v-if="hasYuyin">语音触发</el-radio>
                        <el-radio v-model="sceneForm.sceneType" :label="2">手动触发</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="触发设备" v-if="sceneForm.sceneType==1">
                    <el-select v-model="sceneForm.templateDeviceId" @change="selConditionDevice" style="margin-right:10px;">
                        <el-option v-for="(item,index) in conditionDeviceList" :key="index"
                                   :label="item.deviceName+'('+item.position+')'"
                                   :value="item.templateDeviceId"></el-option>
                    </el-select>
                    <!-- 酒店门锁  -->
                    <div v-if="sceneForm.productType==11004" style="display: inline-block">
                        <el-select v-model="sceneForm.identifier" style="margin-right:10px;">
                            <el-option label="开关" :value="'0.closures_doorlock_basic'"></el-option>
                        </el-select>
                        <el-select v-model="sceneForm.value" style="margin-right:10px;">
                            <el-option label="打开" :value="2"></el-option>
                        </el-select>
                    </div>
                    <!--插卡取电-->
                    <div v-if="sceneForm.productType==3005" style="display: inline-block">
                        <el-select v-model="sceneForm.identifier" style="margin-right:10px;">
                            <el-option label="开关" :value="'0.general_onoff'"></el-option>
                        </el-select>
                        <el-select v-model="sceneForm.value" style="margin-right:10px;">
                            <el-option label="取电" :value="1"></el-option>
                            <el-option label="断电" :value="0"></el-option>
                        </el-select>
                    </div>
                    <!--三路情景开关-->
                    <div v-if="sceneForm.productType==3004" style="display: inline-block">
                        <el-select v-model="sceneForm.identifier" style="margin-right:10px;" @change="selScene()">
                            <el-option label="一路开关" :value="'0.general_scene'"></el-option>
                            <el-option label="二路开关" :value="'1.general_scene'"></el-option>
                            <el-option label="三路开关" :value="'2.general_scene'"></el-option>
                        </el-select>
                        <el-select  v-model="sceneForm.value" style="margin-right:10px;">
                            <el-option v-if="sceneForm.identifier=='0.general_scene'" label="触发" :value="1"></el-option>
                            <el-option v-else-if="sceneForm.identifier=='1.general_scene'" label="触发" :value="2"></el-option>
                            <el-option v-else-if="sceneForm.identifier=='2.general_scene'" label="触发" :value="3"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="场景图标" prop="sceneIcon" v-if="sceneForm.sceneType==2">
                    <!--<div v-if="sceneForm.adminScene != '1'" @click="selSceneIcon(item)" v-model="sceneForm.sceneIcon" v-for="item in sceneIconList" :key="item" :style="sceneForm.sceneIcon == item ? 'border:1px solid #00A521;' : 'border:1px solid #fff;'" style="float: left;padding: 5px;border-radius: 5px;margin: 10px;height:82px;">-->
                        <!--<img :src="item" alt="">-->
                    <!--</div>-->
                    <!--<img v-if="sceneForm.adminScene == '1'" src="https://web.static.baiyatech.com/icon/%E5%88%9D%E5%A7%8B%E5%8C%96.png" alt="" style="'border:1px solid #00A521;padding: 5px;border-radius: 5px;margin: 10px;height:82px;">-->

                    <el-radio-group v-model="sceneForm.sceneIcon"  v-if="sceneForm.adminScene!=1">
                        <el-radio v-for="item in sceneIconList" :key="item" :label="item"><img :src="item" alt=""></el-radio>
                    </el-radio-group>
                    <el-radio-group disabled v-model="sceneForm.sceneIcon"  v-if="sceneForm.adminScene==1">
                        <el-radio  key="https://web.static.baiyatech.com/icon/%E5%88%9D%E5%A7%8B%E5%8C%96.png" label="https://web.static.baiyatech.com/icon/%E5%88%9D%E5%A7%8B%E5%8C%96.png"><img src="https://web.static.baiyatech.com/icon/%E5%88%9D%E5%A7%8B%E5%8C%96.png" alt=""></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="场景命令词" prop="controlMsg" v-if="sceneForm.sceneType==3">
                    <el-input type="text" v-model="sceneForm.controlMsg" autocomplete="off" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <div >设备动作</div>
                    <div v-for="(device,index) in deviceActions" style="margin-bottom:15px;margin-left:5px;" :key="index">
                        <el-select v-model="device.templateDeviceId" @change="selActionDevice(device.templateDeviceId)" style="margin-right:10px;">
                            <el-option v-for="(item,index) in actionDeviceList" :key="index"
                                       :label="item.deviceName+'('+item.position+')'"
                                       :value="item.templateDeviceId"></el-option>
                        </el-select>
                        <!-- 一路开关 -->
                        <div v-if="device.productType==3001" style="display: inline-block">
                            <el-select v-model="device.identifier" style="margin-right:10px;" @change="identChange(index)">
                                <el-option label="一路开关" :value="'0.general_onoff'"></el-option>
                            </el-select>
                            <el-select v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="打开" :value="1"></el-option>
                                <el-option label="关闭" :value="0"></el-option>
                            </el-select>
                        </div>
                        <!-- 二路开关 -->
                        <div v-if="device.productType==3002" style="display: inline-block">
                            <el-select v-model="device.identifier" style="margin-right:10px;" @change="identChange(index)">
                                <el-option label="一路开关" :value="'0.general_onoff'"></el-option>
                                <el-option label="二路开关" :value="'1.general_onoff'"></el-option>
                            </el-select>
                            <el-select v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="打开" :value="1"></el-option>
                                <el-option label="关闭" :value="0"></el-option>
                            </el-select>
                        </div>
                        <!-- 三路开关 -->
                        <div v-if="device.productType==3003" style="display: inline-block">
                            <el-select v-model="device.identifier" style="margin-right:10px;" @change="identChange(index)">
                                <el-option label="一路开关" :value="'0.general_onoff'" ></el-option>
                                <el-option label="二路开关" :value="'1.general_onoff'"></el-option>
                                <el-option label="三路开关" :value="'2.general_onoff'" ></el-option>
                            </el-select>
                            <el-select v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="打开" :value="1"></el-option>
                                <el-option label="关闭" :value="0"></el-option>
                            </el-select>
                        </div>
                        <!-- 方块插座 -->
                        <div v-if="device.productType==4001" style="display: inline-block">
                            <el-select v-model="device.identifier" style="margin-right:10px;" @change="identChange(index)">
                                <el-option label="开关" :value="'0.general_onoff'"></el-option>
                            </el-select>
                            <el-select v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="打开" :value="1"></el-option>
                                <el-option label="关闭" :value="0"></el-option>
                            </el-select>
                        </div>
                        <!-- 窗帘电机 -->
                        <div v-if="device.productType==6002||device.productType==6003" style="display: inline-block">
                            <el-select v-model="device.identifier" style="margin-right:10px;" @change="identChange(index)">
                                <el-option label="进度" :value="'0.tuya_closures_windowcovering_lift_percentage'"></el-option>
                            </el-select>
                            <el-select v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="关" :value="0"></el-option>
                                <el-option label="10%" :value="0.1"></el-option>
                                <el-option label="20%" :value="0.2"></el-option>
                                <el-option label="30%" :value="0.3"></el-option>
                                <el-option label="40%" :value="0.4"></el-option>
                                <el-option label="50%" :value="0.5"></el-option>
                                <el-option label="60%" :value="0.6"></el-option>
                                <el-option label="70%" :value="0.7"></el-option>
                                <el-option label="80%" :value="0.8"></el-option>
                                <el-option label="90%" :value="0.9"></el-option>
                                <el-option label="开" :value="1"></el-option>
                            </el-select>
                        </div>
                        <!-- 调色灯 -->
                        <div v-if="device.productType==2001" style="display: inline-block">
                            <el-select v-model="device.identifier" style="margin-right:10px;" @change="identChange(index)">
                                <el-option label="开关" :value="'0.general_onoff'"></el-option>
                                <el-option label="亮度" :value="'0.general_level'"></el-option>
                                <el-option label="饱和度" :value="'0.colorctrl_saturation'"></el-option>
                                <el-option label="颜色" :value="'0.colorctrl_hue'"></el-option>
                            </el-select>
                            <el-select v-if="device.identifier=='0.general_onoff'" v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="打开" :value="1"></el-option>
                                <el-option label="关闭" :value="0"></el-option>
                            </el-select>
                            <el-select v-else-if="device.identifier=='0.general_level'" v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="0" :value="0"></el-option>
                                <el-option label="10%" :value="0.1"></el-option>
                                <el-option label="20%" :value="0.2"></el-option>
                                <el-option label="30%" :value="0.3"></el-option>
                                <el-option label="40%" :value="0.4"></el-option>
                                <el-option label="50%" :value="0.5"></el-option>
                                <el-option label="60%" :value="0.6"></el-option>
                                <el-option label="70%" :value="0.7"></el-option>
                                <el-option label="80%" :value="0.8"></el-option>
                                <el-option label="90%" :value="0.9"></el-option>
                                <el-option label="100%" :value="1"></el-option>
                            </el-select>
                            <el-select v-else-if="device.identifier=='0.colorctrl_saturation'" v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="0" :value="0"></el-option>
                                <el-option label="10%" :value="0.1"></el-option>
                                <el-option label="20%" :value="0.2"></el-option>
                                <el-option label="30%" :value="0.3"></el-option>
                                <el-option label="40%" :value="0.4"></el-option>
                                <el-option label="50%" :value="0.5"></el-option>
                                <el-option label="60%" :value="0.6"></el-option>
                                <el-option label="70%" :value="0.7"></el-option>
                                <el-option label="80%" :value="0.8"></el-option>
                                <el-option label="90%" :value="0.9"></el-option>
                                <el-option label="100%" :value="1"></el-option>
                            </el-select>
                            <el-select v-else-if="device.identifier=='0.colorctrl_hue'" v-model="device.value" style="margin-right:10px;" @change="selAction">
                                <el-option label="蓝色" :value="230"></el-option>
                                <el-option label="红色" :value="355"></el-option>
                                <el-option label="绿色" :value="130"></el-option>
                                <el-option label="黄色" :value="51"></el-option>
                                <el-option label="橙色" :value="35"></el-option>
                                <el-option label="紫色" :value="293"></el-option>
                            </el-select>
                        </div>
                        <span style="color:#276EF1;cursor:pointer" @click="delAction(index)">删除</span>
                    </div>
                    <div @click="addAction()" style="color:#276EF1;cursor:pointer">新增</div>
                </el-form-item>
                <el-form-item label="" v-if="hasYuyin">
                    <div>语音播报<el-checkbox v-model="sceneForm.replyMark" style="margin-left:10px;"></el-checkbox></div>
                    <el-input type="text" v-model="sceneForm.replyMsg" :disabled="sceneForm.replyMark==0" autocomplete="off" style="width:222px;display: block;margin-left:5px;"></el-input>
                </el-form-item>
                <el-form-item style="text-align: right;position: absolute;bottom:0;right: 30px;">
                    <el-button @click="sceneVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitScene()">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {templateInfo,deviceList,sceneList,saveDevice,removeDevice,updateTempDevice,addScene,removeScene,getSceneDetail,senceIconInfo,getInfraredCode,updateTemplate} from './mouldEdit'
    export default {
        name: "mouldEdit",
        data(){
            return{
                dialogVisible: false, //打开编辑模态框
                deviceVisible:false, //新增设备模态框
                editDeviceVisible:false,//编辑设备模态框
                sceneVisible:false, //新增场景模态框
                templateId:'',
                detailData:{},
                gatewayList:[{
                    deviceName:'二合一网关',
                    productType:1002
                }],
                selDeviceList:[//新增设备表单中左侧可新增的普通设备列表
                    {
                        deviceName:'一路单火开关',
                        productType:3001
                    },{
                        deviceName:'二路单火开关',
                        productType:3002
                    },{
                        deviceName:'三路单火开关',
                        productType:3003
                    },{
                        deviceName:'一路零火开关',
                        productType:3001
                    },{
                        deviceName:'二路零火开关',
                        productType:3002
                    },{
                        deviceName:'三路零火开关',
                        productType:3003
                    },{
                        deviceName:'三路情景开关',
                        productType:3004
                    },{
                        deviceName:'方块插座',
                        productType:4001
                    },{
                        deviceName:'插卡取电',
                        productType:3005
                    },{
                        deviceName:'调色灯带',
                        productType:2001
                    },
                    // {
                    //     deviceName:'窗帘电机(WiFi版)',
                    //     productType:6002
                    // },
                    {
                        deviceName:'电动窗帘',
                        productType:6003
                    },{
                        deviceName:'酒店门锁',
                        productType:11004
                    },{
                        deviceName:'语音助手',
                        productType:121
                    },{
                        deviceName:'万能红外',
                        productType:7902
                    }
                ],
                infraredDeviceList:[//新增设备表单中左侧可新增的红外设备列表
                    {
                        deviceName:'空调',
                        productType:7005
                    },{
                        deviceName:'电视',
                        productType:7002
                    },{
                        deviceName:'风扇',
                        productType:7008
                    },
                ],
                deviceAddList:[],
                deviceListArr:[],
                deviceForm:{ //编辑设备表单
                    deviceName:'',
                    location:'',
                    position:[{}],
                    brandCode:''
                },
                ruleDevice:{
                    position:[
                        {required: true, message: "请输入设备别名", trigger: "blur",},
                    ]
                },
                infraredCodeList:[], //红外设备品牌列表
                isAdd:false, //判断设备列表中是否存在“万能红外”
                hasYuyin:false, //判断设备列表中是否存在“语音助手”
                sceneListData:[], //场景列表
                templateSceneId:'',//场景模板ID
                sceneForm:{ //新增场景表单
                    sceneName:'',
                    sceneType:1,
                    controlMsg:'',
                    sceneIcon:'',
                    adminScene:'',
                    templateDeviceId:'',
                    productType:'',
                    identifier:'',
                    value:'',
                    replyMark:0,
                    replyMsg:'',
                },
                sceneIconList:[],//场景图标列表
                conditionDeviceList:[], //自动场景可选择的触发设备列表
                ruleScene:{
                    sceneName: [
                        {required: true, message: "请输入场景名称", trigger: "blur",},
                    ],
                },
                deviceActions:[], //设备动作列表
                actionDeviceList:[],  //场景动作可选择的设备列表
            }

        },
        methods:{
        //    获取房间模板详情
            GetTemplateInfo() {
                templateInfo(this.templateId,this.$cookies.get("access_token")).then((result) => {
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            console.log('data,', result["data"].data)
                            this.detailData = result["data"].data
                        } else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    }
                })
            },
        //   获取设备列表
            GetDeviceList() {
                deviceList(this.templateId,this.$cookies.get("access_token")).then((result) => {
                    console.log('code',result["data"].code)
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            this.deviceListArr = result['data'].data
                            this.isAdd=false
                            this.hasYuyin=false
                            for(var i=0;i<result['data'].data.length;i++){
                                if(result['data'].data[i].productType===7902){ //设备列表中有万能红外
                                    this.isAdd=true
                                }
                                if(result['data'].data[i].productType===121){ //设备列表中有语音助手
                                    this.hasYuyin=true
                                }
                            }
                        } else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    }
                })
            },
            //   获取场景列表
            GetSceneList() {
                sceneList(this.templateId,this.$cookies.get("access_token")).then((result) => {
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            this.sceneListData = result['data'].data
                            console.log('sceneListData', this.sceneListData)
                            if (result['data'].data.length == 0) {
                                var obj = {
                                    sceneName: '设备初始化',
                                    sceneType: 2,
                                    templateSceneId: '',
                                    adminScene: 1,
                                    deviceCondition: {},
                                    deviceActions: []
                                }
                                addScene(this.templateId, obj, this.$cookies.get("access_token")).then((result) => {
                                    if (result["data"].code == 0) {
                                        // this.$message({
                                        //     message: '保存成功！',
                                        //     type: 'success'
                                        // });
                                    } else {
                                        this.$message({
                                            message: result["data"].message,
                                            type: 'error'
                                        });
                                    }
                                    this.sceneVisible = false
                                    this.GetSceneList()
                                }).catch(() => {
                                    this.$message({
                                        message: '请求失败！',
                                        type: 'error'
                                    });
                                    this.sceneVisible = false
                                })
                            }
                        } else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    }
                })

            },
            //打开添加设备对话框
            openAddDevice(){
                this.deviceAddList=[]
                this.deviceVisible=true

            },
            //添加设备
            addDevice(item){
                if((item.productType==7005||item.productType==7002||item.productType==7008)&&this.isAdd==false){
                        this.$message({
                            message: '请先添加“万能红外”！',
                            type: 'error'
                        });
                }else {
                    var positionVal=[{}]
                    if(item.productType==3002){
                        positionVal=[{},{}]
                    }else if(item.productType==3003){
                        positionVal=[{},{},{}]
                    }else if(item.productType==3005){ //设置默认别名
                        positionVal=[{value:'插卡取电'}]
                    }else if(item.productType==11004){
                        positionVal=[{value:'酒店门锁'}]
                    }else if(item.productType==7902){
                        positionVal=[{value:'万能红外'}]
                    }else if(item.productType==7005){
                        positionVal=[{value:'空调'}]
                    }else if(item.productType==7002){
                        positionVal=[{value:'电视'}]
                    }else if(item.productType==7008){
                        positionVal=[{value:'风扇'}]
                    }
                    if(item.productType==7902){
                        this.isAdd=true
                    }
                    var obj={
                        // deviceName:item.deviceName+(flag+1),
                        deviceName:item.deviceName,
                        productType:item.productType,
                        location:item.location,
                        brandCode:'',
                        position:positionVal,
                        templateId:this.templateId
                    }
                    var arr=this.deviceAddList
                    arr.push(obj)
                    this.deviceAddList=arr
                }

            },
            //获取红外品牌列表
            getCodeByType(productType){
                this.infraredDeviceCode(productType)
            },
            //获取红外品牌列表
            infraredDeviceCode(productType){
                getInfraredCode(productType,this.$cookies.get("access_token")).then((result) => {
                    console.log('code',result["data"].code)
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            this.infraredCodeList = result['data'].data
                        } else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    }
                })
            },
            //在新增模态框中删除设备
            delDevice(index){
                console.log('index',this.deviceAddList[index])
                var arr = this.deviceAddList
                if(this.deviceAddList[index].productType==7902){
                    this.$confirm('删除万能红外后，所有红外设备将一起删除，确认删除此设备吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        arr.splice(index, 1);
                        for(var i=0;i<this.deviceAddList.length;i++){
                            console.log('length',this.deviceAddList.length,i)
                            if(this.deviceAddList[i].productType==7005||this.deviceAddList[i].productType==7002||this.deviceAddList[i].productType==7008){
                                arr.splice(i, 1)
                                i--
                            }
                        }
                        this.isAdd=false
                        this.deviceAddList=arr
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                }else {
                    this.$confirm('确认删除此设备？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        arr.splice(index, 1);
                        this.deviceAddList=arr

                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                }
            },
            //批量添加设备
            submitDevice(){
                var flag=0
                var arr=JSON.parse( JSON.stringify(this.deviceAddList))
                for(var i=0;i<arr.length;i++){
                    var positionList=[]
                    for(var j=0;j<arr[i].position.length;j++){
                        positionList.push(arr[i].position[j].value)
                        console.log('vaue',arr[i].position[j].value)
                        if(arr[i].position[j].value==undefined||arr[i].position[j].value.length==0){
                            flag=1
                        }
                    }
                    arr[i].position=positionList.toString()
                    console.log('lockat',arr[i].location)
                    if(arr[i].productType==7005||arr[i].productType==7002||arr[i].productType==7008){
                        if(arr[i].brandCode==''){
                            flag=1
                        }
                    }else {
                        if(arr[i].location==undefined||arr[i].location.length==0){
                            flag=1
                        }
                    }
                }
                if(flag==0){
                    saveDevice(arr,this.templateId,this.voiceType,this.$cookies.get("access_token")).then((result)=>{
                        if(result["data"].code==0){
                            this.$message({
                                message: '添加成功！',
                                type: 'success'
                            });
                            this.deviceVisible = false
                            this.GetDeviceList()
                            this.deviceAddList=[]
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    }).catch(()=>{
                        this.$message({
                            message: '请求失败！',
                            type: 'error'
                        });
                        // this.deviceVisible = false
                        // this.deviceAddList=[]
                    })
                }else {
                    this.$message({
                        message: '请将设备信息补充完整！',
                        type: 'error'
                    });
                }
            },
            //编辑设备
            editDevice(item){
                if(item.productType==7005||item.productType==7002||item.productType==7008){
                    this.getCodeByType(item.productType)
                }
                this.editDeviceVisible=true
                this.deviceForm=JSON.parse(JSON.stringify(item))
                /*****************给别名字段转换类型***************************/
                var posi=item.position.split(',')
                var obj=[]
                for(var i=0;i<posi.length;i++){
                    obj.push({value:posi[i]})
                }
                this.deviceForm.position=obj
                /*****************给别名字段转换类型***************************/
                console.log('deviceForm',this.deviceForm)
            },
            //更新模板设备
            updateDevice(){
                var flag=0
                    var positionList=[]
                    for(var j=0;j<this.deviceForm.position.length;j++){
                        positionList.push(this.deviceForm.position[j].value)
                        if(this.deviceForm.position[j].value==undefined||this.deviceForm.position[j].value.length==0){
                            flag=1
                        }
                    }
                var position=positionList.toString()
                var obj={
                    deviceName:this.deviceForm.deviceName,
                    productType:this.deviceForm.productType,
                    position:position,
                    location:this.deviceForm.location,
                    templateDeviceId:this.deviceForm.templateDeviceId,
                    brandCode:this.deviceForm.brandCode,
                    templateId:this.templateId
                }
                console.log('loack',obj.location)
                if(obj.position==''){
                    flag=1
                }
                if(obj.productType!=7005&&obj.productType!=7002&&obj.productType!=7008&&obj.location==''){
                    flag=1
                }
                if(flag==0){
                    updateTempDevice(this.templateId,this.voiceType,obj,this.$cookies.get("access_token")).then((result)=>{
                        if(result["data"].code==0){
                            this.$message({
                                message: '修改成功！',
                                type: 'success'
                            });
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                        this.editDeviceVisible = false
                        this.GetDeviceList()
                    }).catch(()=>{
                        this.$message({
                            message: '请求失败！',
                            type: 'error'
                        });
                        this.editDeviceVisible = false
                    })
                }else {
                    this.$message({
                        message: '请将设备信息补充完整！',
                        type: 'error'
                    });
                }


            },
            //删除模板设备
            delMouldDevice(templateDeviceId,productType){
                var flag=0
                console.log('id',productType)
                if(productType==121){
                    console.log('flag',flag)
                    for(var i=0;i<this.sceneListData.length;i++){
                        if(this.sceneListData[i].sceneType==3){
                            flag=1
                        }
                    }
                }
                if(flag==1){
                    this.$message({
                        message: '请先删除语音场景！',
                        type: 'error'
                    });
                }else {


                this.$confirm('确认删除此设备？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    removeDevice(this.templateId,templateDeviceId,this.$cookies.get("access_token")).then((result)=>{
                        if(result["data"].code==0){
                            this.$message({
                                message: '删除成功！',
                                type: 'success'
                            });
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                        this.GetDeviceList()
                    }).catch(()=>{
                        this.$message({
                            message: '请求失败！',
                            type: 'error'
                        });
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
                }
            },

            //保存开机语音播报
            saveWelcomeMsg(){
                updateTemplate(this.templateId,this.detailData.templateName,this.detailData.remarks,this.detailData.welcomeMsg,this.$cookies.get("access_token")).then((result)=>{
                    if(result["data"].code==0){
                        this.$message({
                            message: '修改成功！',
                            type: 'success'
                        });
                        this.GetTemplateInfo();
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },


            //打开新增场景模态框
            openAddScene(type){
                console.log('tye',type)
                if(type=='新增'){
                    this.templateSceneId=''
                    this.sceneForm={ //新增场景表单
                        sceneName:'',
                        sceneType:1,
                        controlMsg:'',
                        sceneIcon:'',
                        adminScene:0,
                        templateDeviceId:'',
                        productType:'',
                        identifier:'',
                        value:'',
                        replyMark:0,
                        replyMsg:''
                    }
                }
                this.sceneVisible = true
                this.conditionDeviceList=[]
                this.deviceActions=[]
                this.actionDeviceList=[]
                this.getSenceIcon()
                //从新增的房间设备中选出可以作触发条件的设备列表
                for(var i=0;i<this.deviceListArr.length;i++){
                    if(this.deviceListArr[i].productType==11004||this.deviceListArr[i].productType==3004||this.deviceListArr[i].productType==3005){
                     //酒店门锁                                     三路情景开关                   插卡取电
                        this.conditionDeviceList.push(this.deviceListArr[i])
                    }
                }
                console.log('conditionDeviceList',this.conditionDeviceList)
                //从新增的房间设备中选出可以作触发设备的设备列表
                for(var j=0;j<this.deviceListArr.length;j++){
                    if(this.deviceListArr[j].productType==3001||this.deviceListArr[j].productType==3002||this.deviceListArr[j].productType==3003||this.deviceListArr[j].productType==4001||this.deviceListArr[j].productType==6002||this.deviceListArr[j].productType==6003||this.deviceListArr[j].productType==2001){
                     //一路开关                                  二路开关                                   三路开关                                方块插座                              窗帘电机                                                                            调色灯                                       语音助手
                        this.actionDeviceList.push(this.deviceListArr[j])

                    }
                }
                this.addAction()
            },
            //获取场景图标列表
            getSenceIcon(){
                senceIconInfo(this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        console.log('data,',result["data"].data)
                        this.sceneIconList=result["data"].data
                        if(this.sceneForm.sceneIcon==''){
                            this.sceneForm.sceneIcon=result["data"].data[0]
                        }

                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            // 选择场景图标
            selSceneIcon(icon){
                this.sceneForm.sceneIcon=icon
            },
            //选择   自动场景下触发条件设备
            selConditionDevice(value){
                console.log(value)
                for(var i=0;i<this.conditionDeviceList.length;i++){
                    if(this.conditionDeviceList[i].templateDeviceId==value){
                        this.sceneForm.productType=this.conditionDeviceList[i].productType
                        if(this.conditionDeviceList[i].productType==11004){ //酒店门锁
                            this.sceneForm.identifier='0.closures_doorlock_basic'
                            this.sceneForm.value=2
                        }else if(this.conditionDeviceList[i].productType==3004){  //三路情景开关
                            this.sceneForm.identifier='0.general_scene'
                            this.sceneForm.value=1
                        }else if(this.conditionDeviceList[i].productType==3005){ //插卡取电
                            this.sceneForm.identifier='0.general_onoff'
                            this.sceneForm.value=1
                        }
                    }
                }
                console.log('form',this.sceneForm)
            },
            //选择三路情景开关
            selScene(){
                if(this.sceneForm.identifier=='0.general_scene'){
                    this.sceneForm.value=1
                }else if(this.sceneForm.identifier=='1.general_scene'){
                    this.sceneForm.value=2
                }if(this.sceneForm.identifier=='2.general_scene'){
                    this.sceneForm.value=3
                }
            },
            //新增设备动作
            addAction(){
                this.deviceActions.push({
                    templateDeviceId:this.actionDeviceList[0].templateDeviceId
                })
                this.selActionDevice(this.actionDeviceList[0].templateDeviceId)
            },
            //选择设备动作
            selActionDevice(value){
                for(var i=0;i<this.actionDeviceList.length;i++){
                    if(this.actionDeviceList[i].templateDeviceId==value){
                        var lng=this.deviceActions.length
                        for(var j=0;j<this.deviceActions.length;j++){
                            if(this.deviceActions[j].templateDeviceId==value){  lng=j}
                        }
                        if(this.actionDeviceList[i].productType==3001){ //一路开关
                            this.deviceActions[lng].identifier='0.general_onoff'
                            this.deviceActions[lng].productType=this.actionDeviceList[i].productType
                            this.deviceActions[lng].value=1
                        }else if(this.actionDeviceList[i].productType==3002){ //二路开关
                            this.deviceActions[lng].identifier='0.general_onoff'
                            this.deviceActions[lng].productType=this.actionDeviceList[i].productType
                            this.deviceActions[lng].value=1
                        }else if(this.actionDeviceList[i].productType==3003){ //三路开关
                            this.deviceActions[lng].identifier='0.general_onoff'
                            this.deviceActions[lng].productType=this.actionDeviceList[i].productType
                            this.deviceActions[lng].value=1
                        }else if(this.actionDeviceList[i].productType==6002||this.actionDeviceList[i].productType==6003){ //窗帘电机
                            this.deviceActions[lng].identifier='0.tuya_closures_windowcovering_lift_percentage'
                            this.deviceActions[lng].productType=this.actionDeviceList[i].productType
                            this.deviceActions[lng].value=1
                        }else if(this.actionDeviceList[i].productType==4001){ //方块插座
                            this.deviceActions[lng].identifier='0.general_onoff'
                            this.deviceActions[lng].productType=this.actionDeviceList[i].productType
                            this.deviceActions[lng].value=1
                        }else if(this.actionDeviceList[i].productType==2001){ //调色灯
                            this.deviceActions[lng].identifier='0.general_onoff'
                            this.deviceActions[lng].productType=this.actionDeviceList[i].productType
                            this.deviceActions[lng].value=1
                        }
                    }
                }
                console.log('deviceActions',this.deviceActions)
            },
            //select做出改变后及时更新表单
            selAction:function(){
                this.$forceUpdate()
            },
            identChange:function(index){
                this.$forceUpdate()
                if(this.deviceActions[index].identifier=='0.general_onoff'||this.deviceActions[index].identifier=='1.general_onoff'||this.deviceActions[index].identifier=='2.general_onoff'){
                    this.deviceActions[index].value=1
                }else if(this.deviceActions[index].identifier=='0.tuya_closures_windowcovering_lift_percentage'||this.deviceActions[index].identifier=='0.general_level'||this.deviceActions[index].identifier=='0.colorctrl_saturation'){
                    this.deviceActions[index].value=1
                }else if(this.deviceActions[index].identifier=='0.colorctrl_hue'){
                    this.deviceActions[index].value=230
                }
            },
            //在新增编辑场景模态框中删除设备动作
            delAction(index){
                this.$confirm('确认删除该动作吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var arr = this.deviceActions
                    arr.splice(index, 1);
                    this.deviceActions=arr
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //提交场景表单
            submitScene:function(){
                console.log('scenefrom',this.sceneForm)
                console.log('this.deviceActions',this.deviceActions)
                if(this.sceneForm.replyMark){
                    this.sceneForm.replyMark=1
                }else {
                    this.sceneForm.replyMark=0
                }

                var obj
                if(this.sceneForm.sceneType==1){ //自动场景
                    obj={
                        sceneName:this.sceneForm.sceneName,
                        sceneType:this.sceneForm.sceneType,
                        adminScene:this.sceneForm.adminScene,
                        templateSceneId:this.templateSceneId,
                        deviceCondition:{
                            identifier:this.sceneForm.identifier,
                            templateDeviceId:this.sceneForm.templateDeviceId,
                            value:this.sceneForm.value,
                            productType:this.sceneForm.productType
                        },
                        deviceActions:this.deviceActions,
                        replyMark:this.sceneForm.replyMark,
                        replyMsg:this.sceneForm.replyMsg,
                    }
                }else if(this.sceneForm.sceneType==2){ //手动场景
                    obj={
                        sceneName:this.sceneForm.sceneName,
                        sceneIcon:this.sceneForm.sceneIcon,
                        sceneType:this.sceneForm.sceneType,
                        adminScene:this.sceneForm.adminScene,
                        templateSceneId:this.templateSceneId,
                        deviceActions:this.deviceActions,
                        replyMark:this.sceneForm.replyMark,
                        replyMsg:this.sceneForm.replyMsg,
                    }
                }else if(this.sceneForm.sceneType==3){ //语音场景
                    obj={
                        sceneName:this.sceneForm.sceneName,
                        sceneType:this.sceneForm.sceneType,
                        controlMsg:this.sceneForm.controlMsg,
                        adminScene:this.sceneForm.adminScene,
                        templateSceneId:this.templateSceneId,
                        deviceActions:this.deviceActions,
                        replyMark:this.sceneForm.replyMark,
                        replyMsg:this.sceneForm.replyMsg,
                    }
                }
                console.log('obj',obj)
                addScene(this.templateId,obj,this.$cookies.get("access_token")).then((result)=>{
                    if(result["data"].code==0){
                        // this.$message({
                        //     message: '保存成功！',
                        //     type: 'success'
                        // });
                        this.sceneVisible = false

                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                    this.GetSceneList()
                }).catch(()=>{
                    this.$message({
                        message: '请求失败！',
                        type: 'error'
                    });
                    // this.sceneVisible = false
                })
            },
            //删除场景
            delScene(templateSceneId){
                this.$confirm('确认删除此场景？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    removeScene(this.templateId,templateSceneId,this.$cookies.get("access_token")).then((result)=>{
                        if(result["data"].code==0){
                            this.$message({
                                message: '删除成功！',
                                type: 'success'
                            });
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                        this.GetSceneList()
                    }).catch(()=>{
                        this.$message({
                            message: '请求失败！',
                            type: 'error'
                        });
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //获取单个场景详情
            editScene(templateSceneId){
                console.log("arr",this.deviceListArr.length==0)
                if(this.deviceListArr.length==0){
                    this.$message({
                        message: '请先添加设备！',
                        type: 'error'
                    });
                }else {
                    getSceneDetail(templateSceneId, this.$cookies.get("access_token")).then((result) => {
                        if (result.data.code == 0) {
                            this.openAddScene('编辑')
                            this.sceneForm.sceneName = result.data.data.sceneName
                            this.sceneForm.sceneIcon = result.data.data.sceneIcon
                            this.sceneForm.sceneType = result.data.data.sceneType
                            this.sceneForm.adminScene = result.data.data.adminScene
                            this.sceneForm.controlMsg = result.data.data.controlMsg
                            this.templateSceneId = result.data.data.templateSceneId
                            this.sceneForm.productType = result.data.data.deviceCondition.productType
                            this.sceneForm.identifier = result.data.data.deviceCondition.identifier

                            this.sceneForm.templateDeviceId = result.data.data.deviceCondition.templateDeviceId
                            this.sceneForm.value = Number(result.data.data.deviceCondition.value)
                            this.sceneForm.replyMark=result.data.data.replyMark === 1 ? true : false
                            this.sceneForm.replyMsg=result.data.data.replyMsg
                            for (var i = 0; i < result.data.data.deviceActions.length; i++) {
                                result.data.data.deviceActions[i].value = Number(result.data.data.deviceActions[i].value)
                            }
                            this.deviceActions = result.data.data.deviceActions
                            this.$forceUpdate()

                        } else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                        this.GetSceneList()
                    }).catch(() => {
                        this.$message({
                            message: '请求失败！',
                            type: 'error'
                        });
                    })
                }
            },

            //获取父组件传递过来的参数
            getParams:function(){
                var templateId=this.$route.query.templateId
                var voiceType=this.$route.query.voiceType
                this.templateId=templateId
                this.voiceType=voiceType
                this.GetTemplateInfo();
                this.GetDeviceList()
                this.GetSceneList()
                console.log('name',this.templateId)
            }

        },
        created() {  /*生命周期函数*/
            this.getParams()

        }
    }
</script>

<style scoped>
    .cont {
        background:#fff;padding:10px;margin-bottom:10px;
    }
    .title {
        font-weight: bold;font-size: 20px;margin-bottom: 10px;
    }
    .rightEdit {
        float:right;
        color:#276EF1;
        margin-right:10px;
    }
    ::v-deep .el-dialog__body {
        padding:0 20px;
        height:75%;
    }
    ::v-deep .el-dialog {
        height:75%;
    }
    .smallDialog ::v-deep  .el-dialog {
        height:50%;
        /*padding-bottom:20px;*/
    }
</style>
